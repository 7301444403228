import React, { useState, useContext, useEffect } from "react";
import Modal from "../modal/Modal";
import { PostType } from "../../models/Post";
import Comments from "./../comments/Comments";
import { CommentType } from "../../models/Comment";
import { apiurl } from "../../App";
import { UserContext } from "../../context/UserContext";

import { MessageContext } from "../../context/MessageContext";
import axios from "axios";
import { LikeType } from "../../models/Like";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { X } from "lucide-react";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  post: PostType;
}
const BreathPostPreview = ({ isOpen, onClose, post }: Props) => {
  const [comments, setComments] = useState<CommentType[]>([]);
  const [likes, setLikes] = useState<LikeType[]>([]);

  const { user, isAuth } = useContext(UserContext);
  const { onError } = useContext(MessageContext);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const isLiked = likes.find((like) => like.user_id === user?.id);

  const likePost = () => {
    if (isAuth && !isLiked) {
      axios
        .post(apiurl + "/likes/", {
          user_id: user.id,
          post_id: post.id,
        })
        .then((res) => {
          if (res.status === 201) {
            setLikes([...likes, res.data.like]);
          }
        })
        .catch(() => onError("failed to like"));
    } else if (isAuth && isLiked) {
      const like = likes.find((like) => like.user_id === user.id);
      axios
        .post(apiurl + "/delete-like/" + like?.id, {
          user_id: user.id,
        })
        .then((res) => {
          if (res.status === 201) {
            const updatedLikes = likes.filter((like) => like.id !== like?.id);
            setLikes(updatedLikes);
          }
        })
        .catch(() => onError("failed to like"));
    } else {
      navigate("/sign");
    }
  };

  useEffect(() => {
    fetch(apiurl + `/comments/${post.id}`)
      .then((res) => res.json())
      .then((data) => {
        setComments(data);
      })
      .catch((err) => console.log(err));

    fetch(apiurl + "/likes/" + post.id)
      .then((res) => res.json())
      .then((data) => setLikes(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="breath-post-preview">
      <Modal isOpen={isOpen} onClose={onClose} theme="white">
        <div className="breath-post-preview__body">
          <X onClick={onClose} size={46} color="#e22758" className="breath-post-preview__close-btn"/>
          <img
            className="breath-post-preview__image"
            src={post.image}
            alt="post"
          />
          <div className="breath-post__info">
            <div className="breath-post__data">
              <p
                dir={"ltr"}
                className="breath-post__description"
                dangerouslySetInnerHTML={{ __html: post.description }}
              />
              <span className="breath-post__icons">
                <i
                  className={classNames("bx", {
                    "bxs-heart": isLiked,
                    "bx-heart": !isLiked,
                  })}
                  onClick={likePost}
                ></i>
                <i
                  className="bx bx-message-rounded-dots"
                  onClick={() => inputRef.current?.focus()}
                ></i>
              </span>
              <p className="breath-post__likes">{likes.length} Likes</p>
            </div>

            <Comments
              inputRef={inputRef}
              comments={comments}
              contentId={post.id}
              replyEndPoint="/reply-post"
              endPoint="/comments"
              isAuth={isAuth}
              updateContent={setComments}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BreathPostPreview;
