import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
const useSpecialPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (
    location.pathname.toLocaleLowerCase().includes("sign") ||
    location.pathname.toLocaleLowerCase().includes("register") ||
    location.pathname.toLocaleLowerCase().includes("admin") ||
    location.pathname === "/"
  )
    return true;
  else return false;
};

export default useSpecialPage;
