import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import empty from "../../assets/svgs/empty-cart.svg";
import { useNavigate } from "react-router-dom";
import { ProductType } from "../../models/Product";
import Button from "../button/Button";
import CartItem from "./CartItem";
import { getTotalPrice } from "../../utils/getTotalPrice";

const Cart = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const getTotal = (items: ProductType[]) => {
    return getTotalPrice(items);
  };

  const onRemoveItem = (id: string) => {
    const updatedCart = user.cart.filter((item) => item.id !== id);
    setUser({ ...user, cart: updatedCart });
  };

  useEffect(() => {
    getTotal(user.cart);
  }, [user]);

  return (
    <div className="cart-page">

      <h2 className="cart-page__header">Cart</h2>

      {!Boolean(user.cart?.length) ? (
        <div className="f-holder">
          <img
            src={String(empty)}
            className="cart-page__empty-cart-image"
            alt="empty cart"
          />
        </div>
      ) : (
        <section className="cart-page__body">
          <ul className="cart-page__items">
            {user.cart?.map((item) => (
              <CartItem item={item} key={item.id} onRemoveItem={onRemoveItem} />
            ))}
          </ul>

          <div className="cart-page__cart-info">
            <h3 className="cart-page__total">
              The Total of Items:
              <br />
              <b>{getTotal(user.cart)}</b> SAR
            </h3>
          </div>
          <Button
            text="Checkout"
            type="button"
            onClick={() => navigate("/checkout")}
            orange
          />
        </section>
      )}
    </div>
  );
};

export default Cart;
