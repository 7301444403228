import React from "react";
import { BlogType } from "../../models/Blog";
import { Link } from "react-router-dom";

interface Props {
  blog: { slug: string; content: BlogType };
}

const BlogTeaser = ({ blog }: Props) => {
  return (
    <Link className="blogs-page__blog-teaser" to={`/blog/${blog.slug}`}>
      <div className="blogs-page__blog-teaser-info">
        <h3 className="blogs-page__blog-teaser-title">{blog.content.title}</h3>
      </div>
      <img
        className="blogs-page__blog-teaser-image"
        src={blog.content.image}
        alt={blog.content.title}
      />
    </Link>
  );
};

export default BlogTeaser;
