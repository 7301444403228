import React from "react";

const HeartIcon = ({
  classNames,
  bgColor = "#FC7E29",
  iconColor = "#FFDCC5",
}) => {
  return (
    <div className={classNames}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180" fill="none">
        <path
          fill={bgColor}
          fill-rule="evenodd"
          stroke="#191E32"
          stroke-miterlimit="10"
          stroke-width="4.3"
          d="M83 3a80 80 0 1 1 0 161A80 80 0 0 1 83 3Z"
          clip-rule="evenodd"
        />
        <path
          fill={iconColor}
          fill-rule="evenodd"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="4.3"
          d="M83 60c10-18 30-18 40-9s10 26 0 44c-7 13-25 26-40 34-14-8-32-21-39-34-10-18-10-35 0-44s29-9 39 9Z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  );
};

export default HeartIcon;
