import React from "react";
import Aos from "aos";

import "./css/animation.css";
import "aos/dist/aos.css";

import AppRouter from "./AppRouter";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import useSpecialPage from "./utils/useSpecialPage";

import UserContextProvider from "./context/UserContext";
import MessageContextProvider from "./context/MessageContext";

Aos.init();

export const apiurl = process.env.REACT_APP_BASE_API_URL;

function App() {
  const specialPage = useSpecialPage();
  return (
    <UserContextProvider>
      <MessageContextProvider>
        <main className="app">
          {!specialPage && <Navbar />}
          <AppRouter />
          <Footer />
        </main>
      </MessageContextProvider>
    </UserContextProvider>
  );
}

export default App;
