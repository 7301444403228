import React from "react";

const GalleryHeaderTabs = ({ classNames }) => {
  return (
    <div className={classNames}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 957 713" fill="none">
        <path
          fill="#fff"
          d="M46 141h910v513c0 8-6 14-14 14H59c-7 0-13-6-13-14V141Z"
        />
        <path
          fill="#000"
          d="M942 669H59c-8 0-14-7-14-14V141l1-1h910l1 1v514c0 7-7 14-15 14ZM47 142v513c0 6 5 12 12 12h883c7 0 12-6 12-12V142H47Z"
        />
        <mask
          id="a"
          width="911"
          height="59"
          x="45"
          y="82"
          maskUnits="userSpaceOnUse"
          style={{ maskType: "luminance" }}
        >
          <path
            fill="#fff"
            d="M59 83h883c8 0 14 6 14 13v45H46V96c0-7 6-13 13-13Z"
          />
        </mask>
        <g fill="#FF5052" mask="url(#a)">
          <path d="M69 83H46v58h23V83ZM93 83H69v58h24V83ZM116 83H93v58h23V83ZM139 83h-23v58h23V83ZM163 83h-24v58h24V83ZM186 83h-23v58h23V83ZM209 83h-23v58h23V83ZM232 83h-23v58h23V83ZM256 83h-24v58h24V83ZM279 83h-23v58h23V83ZM302 83h-23v58h23V83ZM326 83h-24v58h24V83ZM349 83h-23v58h23V83ZM372 83h-23v58h23V83ZM396 83h-24v58h24V83ZM419 83h-23v58h23V83ZM442 83h-23v58h23V83ZM466 83h-24v58h24V83ZM489 83h-23v58h23V83ZM512 83h-23v58h23V83ZM536 83h-24v58h24V83ZM559 83h-23v58h23V83ZM582 83h-23v58h23V83ZM606 83h-24v58h24V83ZM629 83h-23v58h23V83ZM652 83h-23v58h23V83ZM676 83h-24v58h24V83ZM699 83h-23v58h23V83ZM722 83h-23v58h23V83ZM746 83h-24v58h24V83ZM769 83h-23v58h23V83ZM792 83h-23v58h23V83ZM816 83h-24v58h24V83ZM839 83h-23v58h23V83ZM862 83h-23v58h23V83ZM886 83h-24v58h24V83ZM909 83h-23v58h23V83ZM932 83h-23v58h23V83ZM956 83h-24v58h24V83Z" />
        </g>
        <path
          fill="#000"
          d="M956 142H46l-1-1V96c0-8 6-14 14-14h883c8 0 15 6 15 14v45l-1 1Zm-909-2h907V96c0-7-5-12-12-12H59c-7 0-12 5-12 12v44Z"
        />
        <path
          fill="#FFDCC5"
          d="M891 167H111c-5 0-9 3-9 8v404c0 5 4 8 9 8h780c4 0 8-3 8-8V175c0-5-4-8-8-8Z"
        />
        <path
          fill="#000"
          d="M890 589H111c-5 0-10-5-10-10V175c0-5 5-9 10-9h779c6 0 10 4 10 9v404c0 5-4 10-10 10ZM111 168c-4 0-7 3-7 7v404c0 4 3 7 7 7h779c4 0 8-3 8-7V175c0-4-4-7-8-7H111Z"
        />
        <path
          fill="#FC7E29"
          d="M287 639c10 0 18-8 18-18s-8-18-18-18-19 8-19 18 9 18 19 18Z"
        />
        <path
          fill="#000"
          d="M287 640c-11 0-20-8-20-19 0-10 9-19 20-19s19 9 19 19c0 11-8 19-19 19Zm0-36c-10 0-18 8-18 17 0 10 8 17 18 17 9 0 17-7 17-17 0-9-8-17-17-17Z"
        />
        <path
          fill="#FFDCC5"
          d="m268 621 2-8H111c-5 0-9 4-9 8 0 5 4 9 9 9h159l-2-9Z"
        />
        <path
          fill="#000"
          d="M270 631H111c-5 0-10-5-10-10s5-9 10-9h160v2l-2 7 2 8v1l-1 1Zm-159-17c-4 0-7 3-7 7s3 7 7 7h158a18 18 0 0 1 0-14H111Z"
        />
        <path
          fill="#FFDCC5"
          d="M890 613H303l2 8-2 9h587c5 0 9-4 9-9 0-4-4-8-9-8Z"
        />
        <path
          fill="#000"
          d="M890 631H303l-1-1v-1l2-8-2-7v-2h588c6 0 10 4 10 9s-4 10-10 10Zm-585-3h585c5 0 8-3 8-7s-3-7-8-7H305a18 18 0 0 1 0 14ZM915 113v-2l19-19h2v2l-20 19h-1Z"
        />
        <path fill="#000" d="M935 113h-1l-19-19v-2h1l20 19v2h-1Z" />
        <path
          fill="#fff"
          d="M1 185h910v513c0 8-6 13-13 13H14c-7 0-13-5-13-13V185Z"
        />
        <path
          fill="#000"
          d="M897 713H14c-8 0-14-7-14-15V185l1-1h910l1 1v513c0 8-7 15-15 15ZM2 186v512c0 7 6 12 12 12h883c7 0 13-5 13-12V186H2Z"
        />
        <path
          fill="#E22758"
          d="M14 126h884c7 0 13 6 13 14v45H1v-45c0-8 6-14 13-14Z"
        />
        <path
          fill="#000"
          d="M911 186H1l-1-1v-46c0-7 6-14 14-14h883c8 0 15 7 15 14v46l-1 1ZM2 184h908v-44c0-7-6-12-13-12H14c-6 0-12 5-12 12v44Z"
        />
        <path
          fill="#FFDCC5"
          d="M846 211H66c-5 0-8 3-8 8v404c0 5 3 8 8 8h780c4 0 8-3 8-8V219c0-5-4-8-8-8Z"
        />
        <path
          fill="#000"
          d="M846 632H66c-5 0-9-4-9-9V219c0-5 4-10 9-10h780c5 0 9 5 9 10v404c0 5-4 9-9 9ZM66 212c-4 0-7 3-7 7v404c0 4 3 7 7 7h780c4 0 7-3 7-7V219c0-4-3-7-7-7H66Z"
        />
        <path
          fill="#FC7E29"
          d="M242 683c10 0 18-8 18-18s-8-18-18-18-18 8-18 18 8 18 18 18Z"
        />
        <path
          fill="#000"
          d="M242 684c-11 0-20-8-20-19 0-10 9-19 20-19s20 9 20 19c0 11-9 19-20 19Zm0-36c-10 0-17 8-17 17s7 17 17 17 17-8 17-17-7-17-17-17Z"
        />
        <path
          fill="#FFDCC5"
          d="M224 665c0-3 0-6 2-8H66c-4 0-8 4-8 8 0 5 4 8 8 8h160c-2-2-2-5-2-8Z"
        />
        <path
          fill="#000"
          d="M226 675H66c-5 0-9-5-9-10s4-9 9-9h161v1c-2 3-2 6-2 8 0 3 0 5 2 8v1l-1 1ZM66 658c-4 0-7 3-7 7s3 7 7 7h158a18 18 0 0 1 0-14H66Z"
        />
        <path
          fill="#FFDCC5"
          d="M846 657H258c2 2 2 5 2 8s0 6-2 8h588c4 0 8-3 8-8 0-4-4-8-8-8Z"
        />
        <path
          fill="#000"
          d="M846 675H258l-1-1v-1c2-3 2-5 2-8 0-2 0-5-2-8v-1h589c5 0 9 4 9 9s-4 10-9 10Zm-586-3h586c4 0 7-3 7-7s-3-7-7-7H260a18 18 0 0 1 0 14ZM871 157h-1v-2l20-19h1v1l-20 20Z"
        />
        <path fill="#000" d="m890 157-20-20v-1h1l20 19v2h-1Z" />
        <path
          fill="#E22758"
          d="M794 41c12 0 21-9 21-20s-9-20-21-20c-11 0-20 9-20 20s9 20 20 20Z"
        />
        <path
          fill="#000"
          d="M794 42c-11 0-21-9-21-21s10-21 21-21c12 0 22 9 22 21s-10 21-22 21Zm0-40c-10 0-19 9-19 19s9 19 19 19c11 0 20-9 20-19s-9-19-20-19Z"
        />
        <path
          fill="#FFDCC5"
          d="M862 41c11 0 20-9 20-20s-9-20-20-20c-12 0-21 9-21 20s9 20 21 20Z"
        />
        <path
          fill="#000"
          d="M862 42c-12 0-22-9-22-21s10-21 22-21c11 0 21 9 21 21s-10 21-21 21Zm0-40c-11 0-20 9-20 19s9 19 20 19c10 0 19-9 19-19s-9-19-19-19Z"
        />
        <path
          fill="#FF5052"
          d="M929 41c11 0 20-9 20-20s-9-20-20-20c-12 0-21 9-21 20s9 20 21 20Z"
        />
        <path
          fill="#000"
          d="M929 42c-12 0-22-9-22-21s10-21 22-21c11 0 21 9 21 21s-10 21-21 21Zm0-40c-11 0-20 9-20 19s9 19 20 19c10 0 19-9 19-19s-9-19-19-19Z"
        />
        <path
          fill="#FF5052"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="2"
          d="m134 435 13-3c16-5 27-15 35-29 7-13 11-26 14-40l2-15v-1l1 11c3 17 7 34 17 49 11 16 25 26 45 28h1l-10 2c-17 4-29 14-37 28-8 12-12 26-15 40l-2 18-1-11c-3-17-7-34-17-49a58 58 0 0 0-43-27l-3-1Z"
        />
        <path
          fill="#FC7E29"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="2"
          d="m107 442 8-1c10-3 17-10 22-18 4-8 6-16 8-25l1-9 1 6c1 11 4 21 10 30 7 10 16 16 28 17l-6 1c-10 3-17 9-23 18-4 7-7 15-8 24l-2 11-1-6c-1-11-4-21-10-30-6-10-15-16-26-17l-2-1Z"
        />
        <path
          fill="#E22758"
          d="m770 494-2-2h-1v-1l2-2 1 1v10l-1 1v1h4v-1l-2-1v-10l4 12h3l4-10v11h4v-13l1-1v-1h-5l-4 11-3-11h-5c-4 0-5 2-5 4s1 3 2 3c2 0 3 0 3-2v1Z"
        />
        <path
          fill="#FF5052"
          d="m771 505-1 1h-6l1-1 2-2v-3l-2-2h-3l-1 1-1 3 3 1 1-1-1-1v1h-1v-2c2-1 3 0 4 1v2l-1 1h-1l-1 1v1l-1-1v-1l-3-1-1-1 1-2h1l-1-2h-1l-2 2h1v1-1h1v1l-1 2 2 2 1 1v4c0 3 1 5 3 5 1 0 3-1 3-3 0 1 1 3 3 3s2-2 2-5v-5Zm-8 7c-1 0-2 0-2-2l1-1h4c0 2 0 3-3 3Zm6-1c-2 0-3 0-3-2h4l1 1-2 1Z"
        />
        <path
          fill="#FC7E29"
          d="M779 505c2 0 5 0 5 3l-2 1c-2 0-1-3-4-3l-1 2v5l3-2c2 0 4 2 4 4 0 3-3 4-6 4-2 0-4 0-4-3l1-1c3 0 2 3 4 3l1-2c0-3-6-2-6-7 0-3 2-4 5-4ZM751 491l-2-1v-1l3 1-2-2 1-1 2 1v1h-1l-1-1 1 2h-3l2 1h1l-1 1v-1ZM754 485h1-1v-1 1-1h1l1 1-1 1h-1v-1Zm1 0v1-1ZM758 483l-1-2h-1l1-1 1 1h1l1 1-1 1v-2h-1l1 1c0 1 0 0 0 0v1h-1ZM763 479v-1 1h-1l1-1h1v1l1 1h-3l1-1Zm0 1h1l-1-1v1ZM770 475h1l1 1v1h1l-2 1h-1v-3Zm1 1v-1 2-1Zm0 2 1-1h-1v1ZM775 478v-2h1v2h-1Zm1-3v1-1ZM779 479v-2a49 49 0 0 0 1 0h1l1 1-1 1h1l-1 1-1-1h1v-2l-1 1v1h-1ZM789 480h1l-1 1v-1h-1l1 2h-3l1-1v1h1v-2h1ZM792 484h-1v-1l1 1 1 1-1 1h-1v-1h-1v-1h2Zm-1 1h1-1ZM793 488h1l1-1 1-1v1l-1 1h1v1l-1 1-1-1 1-1h1-1l-1 1-1-1ZM796 492l2-1c1 0 1 0 0 0h1a45 45 0 0 0 0 1l-2 1-1 1v-2ZM749 512l2 1v1-1h-2l-1 1h1v-1h1v2h-1l-1-1 1-2ZM750 517s0 1 0 0h2v-1 1l1 1h-1v-1l-1 1v1l-1-2ZM755 521v-1l-1 1v-1h1v2h-1v1-1h-1v-1h2Zm-1 0v1-1ZM756 525h1l1-2v-1l1 1v2h-2v1l-1-1Zm2-1h1v-1l-1 1ZM761 527l1-2h1v1l1 1-1 1h1l-1 1-1-1h1v-2l-1 1v1l-1-1ZM766 529l1-1v-1l1 1-1 1v1l-1-1Zm1-3 1 1h-1v-1ZM770 529l1-1 1 1-1-1v2h1-1l-1-1ZM778 530v-1l-1-2 2-1 1 2c1 1 0 1-1 2h-1Zm1-1v-2h-1l1 2ZM784 526h1l-1 1 1 1v-1l-1 1-1-1 1-1Zm0 0v1-1ZM787 525v-1h1-1l2 1-1 1h-1 1v-1h-1ZM790 521c0 1 0 1 0 0-1 0-1 0 0 0Zm1 3v-1l-1-1v-1h1l1 2-1 1ZM794 521v-1h-1c0-1 0-1 0 0l-1-1h1v-1h3v1l-1 1v-1l-1-1h-1v1l1 1h1l-1 1ZM796 514h1v1h1v-1 1l-2 1v-2Zm1 0h-1v1h1v-1ZM799 512l-1-1h-1v-1h1c-1 0-1 0 0 0v-1 1l1 1h1l-1 1ZM798 502l1-1v-1l1-1v2l1 1-1 1v1h-1v-2h-1ZM745 502h1l1-1v-2 1l1 1 1 1-1 1-1 1v-2h-2Z"
        />
        <path
          fill="#FA8837"
          d="M284 279h85v28h-6c0-16-14-21-25-21-8 0-15 3-15 7v42c25 0 23-11 24-16h6v38h-6c-1-4 1-15-24-15v42c0 4 7 8 15 8 11 0 25-6 25-22h6v28h-88v-6c3 0 10-3 10-8v-91c0-5-5-7-10-7-16 0-24 12-24 21 0 4 2 7 6 7 3 0 6-1 8-5 9 1 13 9 13 16 0 10-9 16-20 16-16 0-25-13-25-27 0-17 13-35 45-35ZM374 398v-6c4 0 12-3 12-8v-91c0-5-6-8-10-8v-6h50l28 79v-65c0-5-9-8-12-8v-6h32v6c-3 0-12 3-12 8v105h-30l-37-104v90c0 5 9 8 12 8v6h-33ZM506 371c0 12 2 22 9 22 6 0 16-5 16-23v-77c0-5-7-8-10-8v-6h53v6c-4 0-10 3-10 8v70c0 34-35 37-49 37-15 0-36-6-36-25l27-4ZM586 338c0-36 13-61 51-61s51 25 51 61c0 37-12 62-51 62-38 0-51-25-51-62Zm51 55c17 0 17-37 17-54 0-18 1-55-17-55-17 0-17 38-17 55s0 54 17 54ZM744 279v6c-3 0-9 4-7 8l18 40c7-14 14-33 14-41 0-4-2-7-6-7v-6h33v6c-2 0-28 37-37 57v42c0 5 7 8 10 8v6h-52v-6c3 0 10-3 10-8v-42l-24-49c-2-4-7-8-10-8v-6h51Z"
        />
        <path
          fill="#E22758"
          d="M239 460h53l2 18-4 1c-3-9-8-15-15-15v56c0 2 4 4 6 4v4h-30v-4c2 0 5-2 5-4v-56c-7 0-12 6-15 15l-3-1 1-18ZM296 464v-4h30v4c-2 0-5 2-5 4v24h19v-24c0-2-4-4-6-4v-4h30v4c-2 0-5 2-5 4v52c0 2 3 4 5 4v4h-30v-4c2 0 6-2 6-4v-24h-19v24c0 2 3 4 5 4v4h-30v-4c2 0 6-2 6-4v-52c0-2-4-4-6-4ZM417 460v16h-3c0-9-8-12-14-12-5 0-8 2-8 4v24c13 0 12-6 13-9h4v22h-4c-1-3 0-9-13-9v24c0 2 3 4 8 4 6 0 14-3 14-12h3v16h-50v-4c2 0 6-2 6-4v-52c0-2-4-4-6-4v-4h50ZM464 459c6 0 13 2 18 4l2 15-4 1c-2-7-5-16-14-16-5 0-11 5-11 11 0 13 29 12 29 34 0 14-13 21-26 21-6 0-13-2-18-4l-2-15 4-1c2 7 5 16 14 16 5 0 11-5 11-10 0-14-29-14-29-35 0-14 14-21 26-21ZM487 464v-4h30v4c-2 0-6 2-6 4v24h19v-24c0-2-3-4-5-4v-4h30v4c-2 0-6 2-6 4v52c0 2 4 4 6 4v4h-30v-4c2 0 5-2 5-4v-24h-19v24c0 2 4 4 6 4v4h-30v-4c2 0 5-2 5-4v-52c0-2-3-4-5-4ZM562 494c0-21 7-35 29-35 21 0 28 14 28 35 0 20-7 35-28 35-22 0-29-14-29-35Zm29 31c9 0 9-21 9-31s1-31-9-31-10 21-10 31 0 31 10 31ZM644 460h28v4c-2 0-5 2-4 4l12 47 9-35-3-12c0-2-3-4-5-4v-4h29v4c-2 0-5 2-4 4l12 47c4-16 11-40 11-48 0-2-1-3-4-3v-4c20 0 36-25 57-25 7 0 15 5 15 13 0 4-3 8-10 8 0-4-3-6-9-6-9 0-26 6-33 13-11 11-20 45-26 65h-17l-10-38-11 38h-17l-16-60c0-3-3-4-6-4-6 0-9 6-9 11 0 3 1 5 3 5s4-1 5-3c5 1 8 5 8 9 0 6-6 9-12 9-9 0-15-8-15-16 0-9 7-19 22-19Z"
        />
      </svg>
    </div>
  );
};

export default GalleryHeaderTabs;
