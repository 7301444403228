import React, { useContext } from "react";
import CartItem from "../cart/CartItem";
import { UserContext } from "../../context/UserContext";
import { getTotalPrice } from "../../utils/getTotalPrice";
import CheckoutSummary from "./CheckoutSummary";

const Checkout = () => {

  const { user } = useContext(UserContext);

  return (
    <div className="checkout-page">
      <h1 className="checkout-page__header">Checkout</h1>

      <ul className="checkout-page__items">
        {user.cart?.map((item) => (
          <CartItem item={item} key={item.id} />
        ))}
      </ul>
      <CheckoutSummary
        allowed={Number(getTotalPrice(user.cart)) === 0}
        cart={user.cart}
      />
    </div>
  );
};

export default Checkout;
