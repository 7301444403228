import React, { FormEvent, useContext, useState } from "react";
import Comment from "./Comment";
import UserCommentPost from "../user-comment-post/UserCommentPost";
import {  apiurl } from "../../App";
import { UserContext } from "../../context/UserContext";

import { MessageContext } from "../../context/MessageContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CommentType } from "../../models/Comment";

interface Props {
  isAuth: boolean;
  comments: CommentType[];
  contentId: string;
  updateContent: (data: any) => void;
  endPoint: string;
  replyEndPoint: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}
const Comments = ({
  comments,
  isAuth,
  contentId,
  updateContent,
  endPoint,
  replyEndPoint,
  inputRef,
}: Props) => {
  const { user } = useContext(UserContext);
  const { onError, onSuccess } = useContext(MessageContext);

  const [comment, setComment] = useState<string>("");
  const navigate = useNavigate();

  const onPostComment = (e: FormEvent) => {
    e.preventDefault();
    if (isAuth) {
      axios
        .post(apiurl + endPoint, {
          user_id: user.id,
          content_id: contentId,
          comment,
        })
        .then((res) => {
          const { data } = res;
          if (data.error) {
            onError(data.error);
          } else {
            updateContent((prev: CommentType[]) => [data.comment, ...prev]);
            onSuccess(data.message);
            setComment("");
          }
        })
        .catch(() => onError("Failed to Post your comment!"));
    } else {
      navigate("/sign");
    }
  };

  return (
    <div className="comments">
      <UserCommentPost
        inputRef={inputRef}
        placeholder="Post your Comment..."
        avatar={user.avatar}
        comment={comment}
        onChange={(e) => setComment(e.target.value)}
        onPost={onPostComment}
      />
      {comments.length === 0 ? (
        <p>No comments here.</p>
      ) : (
        comments?.map((comment) => (
          <Comment
            replyEndPoint={replyEndPoint}
            key={comment.id}
            comment={comment}
            isAuth={isAuth}
          />
        ))
      )}
    </div>
  );
};

export default Comments;
