import React from "react";
import { Link } from "react-router-dom";

import { socialMediaLinks } from "./social-meida-links";
import mahaLogo from "../../assets/svgs/logo.svg";
import LandWaves from "../../assets/splashes/LandWaves";

const Footer = () => {
  return (
    <footer className="footer">
      <LandWaves classnames="footer__waves-1" />
      <LandWaves classnames="footer__waves-2" />
      <div className="footer__content">
        <div className="footer__social-icons">
          {socialMediaLinks.map((icon) => (
            <a
              className="footer__social-icon"
              href={icon.link}
              target="_blank"
              key={icon.name}
            >
              <img src={icon.icon} alt={icon.name} className="footer__icon" />
            </a>
          ))}
        </div>
        <div className="footer__pages">
          <Link className="footer__page-link" to="/about">
            About me
          </Link>
          <Link className="footer__page-link" to="/blog">
            Blog
          </Link>
          <Link className="footer__page-link" to="/shop">
            shop
          </Link>
          <Link className="footer__page-link" to="/gallery">
            gallery
          </Link>
          <Link className="footer__page-link" to="/breath">
            breathe
          </Link>
        </div>
        <img className="footer__logo" src={String(mahaLogo)} alt="Maha logo" />
      </div>
    </footer>
  );
};

export default Footer;
