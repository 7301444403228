import React from "react";
import { CircleCheck, Info } from "lucide-react";

interface Props {
  show: boolean;
  content: string;
  type: "error" | "success";
}
const Message = ({ content, type, show }: Props) => {


  if (show)
    return (
      <div className="message">
       {type=== "error" ? <Info color="#e22758" size={24} /> : <CircleCheck color="#fc7e29" size={24} />}
        <p className="message__text">{content}</p>
      </div>
    );
};

export default Message;
