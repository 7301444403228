import React, { useEffect, useState } from "react";

import BlogSection from "./BlogSection";
import DiscoverSection from "./DiscoverSection";
import GreatThingsSection from "./GreatThingsSection";
import FeedbackSliderSection from "./FeedbackSliderSection";
import Navbar from "../navbar/Navbar";
import Land from "./Land";
import ShopSection from "./ShopSection";


const Home = () => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  const setFixedNavbar = () => {
    if (window.scrollY >= 560) {
      setIsNavbarFixed(true);
    } else {
      setIsNavbarFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", setFixedNavbar);
  }, []);

  return (
    <main className="home-page">
      {isNavbarFixed ? <div style={{ height: "15vh" }}></div> : null}
      <Land />
      <Navbar />
      <DiscoverSection />
      <GreatThingsSection />
      <ShopSection />
      <FeedbackSliderSection />
      <BlogSection />
    </main>
  );
};

export default Home;
