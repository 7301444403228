import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MessageContext } from "../../context/MessageContext";
import { apiurl } from "../../App";
import { Tabs } from "antd";
import AddBlogForm from "./AddBlogForm";
import AddProductForm from "./AddProductForm";
import AddProjectForm from "./AddProjectForm";
import Button from "../button/Button";
const AdminPanel = () => {
  const { onError, onSuccess } = useContext(MessageContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    let emailStored = localStorage.getItem("email");
    axios
      .post(apiurl + "/isAdmin", {
        email: emailStored,
      })
      .then((res) => {
        const { data } = res;
        if (data.error) {
          navigate("/notfound");
        } else {
          console.log("welcome Maha");
        }
      })
      .catch((err) => {
        console.error(err);
        navigate("/notfound");
      });
  }, []);

  const [pdata, setPdata] = useState({
    name: "",
    price: 0,
    cate: "",
    discount: 0,
    image: "",
    describe: "",
    image2: "",
    file: "",
  });
  const [BlogData, setBlogsData] = useState({
    name: "",
    image: "",
    content: "",
  });
  const [ProjectData, setProjectData] = useState({
    title: "",
    main_image: "",
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setPdata({ ...pdata, file: base64String });
    };

    reader.readAsDataURL(file);
  };

  const handleMainImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setPdata({ ...pdata, image: base64String });
    };

    reader.readAsDataURL(file);
  };
  const handleSecondImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setPdata({ ...pdata, image2: base64String });
    };

    reader.readAsDataURL(file);
  };

  const handleBlogImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setBlogsData({ ...BlogData, image: base64String });
    };

    reader.readAsDataURL(file);
  };
  const handleMainProjectImage = (event) => {
    const files = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setProjectData({ ...ProjectData, main_image: base64String });
    };

    reader.readAsDataURL(files);
  };
  const addProduct = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(apiurl + "/products", {
        title: pdata.name,
        price: pdata.price,
        category: pdata.cate,
        main_image: pdata.image,
        second_image: pdata.image2,
        discount: pdata.discount,
        description: pdata.describe,
        file: pdata.file,
      })
      .then((res) => {
        const { data } = res;
        if (data.error) {
          onError(data.error);
          setLoading(false);
        } else {
          onSuccess("Posted new Product!");
          setLoading(false);
        }
      })
      .catch((err) => {
        onError(String(err));
      });
  };
  const addBlog = (e) => {
    e.preventDefault();
    axios
      .post(apiurl + "/blogs", {
        title: BlogData.name,
        content: BlogData.content,
        image: BlogData.image,
      })
      .then((res) => {
        const { data } = res;
        if (data.error) {
          onError(data.error);
        } else {
          onSuccess("Posted new Blog!");
        }
      })
      .catch((err) => {
        onError(String(err));
      });
  };

  const items = [
    {
      key: "1",
      label: `Blog`,
      children: (
        <AddBlogForm
          BlogData={BlogData}
          setBlogsData={setBlogsData}
          handleBlogImage={handleBlogImage}
          addBlog={addBlog}
        />
      ),
    },
    {
      key: "2",
      label: `Product`,
      children: (
        <AddProductForm
          addProduct={addProduct}
          pdata={pdata}
          setPdata={setPdata}
          handleFileUpload={handleFileUpload}
          handleMainImage={handleMainImage}
          handleSecondImage={handleSecondImage}
          loading={loading}
        />
      ),
    },
    {
      key: "3",
      label: `Project`,
      children: (
        <AddProjectForm
          setProjectData={setProjectData}
          ProjectData={ProjectData}
          handleMainProjectImage={handleMainProjectImage}
        />
      ),
    },
  ];

  return (
    <main className="admin-panel">
      <Button
        type="button"
        onClick={() => (window.location.href = "/")}
        pink
        text="Home Page"
      />

      <h1 className="admin-panel__header">ADMIN PANEL</h1>

      <h2 className="admin-panel__add-title">Add New!!</h2>
      <Tabs defaultActiveKey="1" items={items} />
    </main>
  );
};

export default AdminPanel;
