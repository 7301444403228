import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiurl } from "../../App";
import { useState } from "react";
import Comments from "../comments/Comments";
import { CommentType } from "../../models/Comment";
import { ProjectType } from "../../models/Project";
import Loading from "../loading/Loading";
import { getStoryblokData } from "../../utils/getStoryblokData";
import { UserContext } from "../../context/UserContext";

export default function Project() {
  const [projectComments, setProjectComments] = useState<CommentType[]>([]);
  const [project, setProject] = useState<ProjectType>();
  const [loading, setLoading] = useState(true);

  const { isAuth } = useContext(UserContext);
  const param = useParams();

  useEffect(() => {
    const getProject = async () => {
      const { data: projectdata } = await getStoryblokData<{
        story: { content: ProjectType };
      }>(`cdn/stories/projects/${param?.slug}`);
      setProject(projectdata?.story.content);
      setLoading(false);
    };
    getProject();
    fetch(apiurl + "/comments/" + param.slug)
      .then((res) => res.json())
      .then((data) => {
        setProjectComments(data);
      })
      .catch((err) => console.error(err));
  }, []);
  //==================================

  if (loading) return <Loading />;

  return (
    <div className="project-page">
      <h2 className="project-page__title">{project?.title}</h2>
      <div className="project-page__images ">
        <img
          className="project-page__image"
          src={project?.main_image}
          alt="project"
        />
        {project?.images.map((image, index) => (
          <img
            key={index}
            className="project-page__image"
            src={image?.filename as string}
            alt="project"
          />
        ))}
      </div>
      <hr />
      {project && (
        <Comments
          comments={projectComments}
          contentId={param.slug!}
          endPoint="/comments"
          isAuth={isAuth}
          replyEndPoint="/replies"
          updateContent={setProjectComments}
        />
      )}
    </div>
  );
}
