import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProjectType } from "../../models/Project";
import HeaderHomeLogo from "../elements/HeaderHomeLogo";
import Loading from "../loading/Loading";
import { getStoryblokData } from "../../utils/getStoryblokData";
import { ArrowRight } from "lucide-react";
import GalleryHero from "./GalleryHero";
export default function Gallery() {
  const [projects, setProjects] =
    useState<{ slug: string; content: ProjectType }[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProjects = async () => {
      const { data: blogData } = await getStoryblokData<{
        stories: { slug: string; content: ProjectType }[];
      }>("cdn/stories/", "projects");
      setProjects(blogData?.stories);
      setLoading(false);
    };
    getProjects();
  }, []);

  return (
    <div className="gallery-page">
      <GalleryHero />
      {loading ? (
        <Loading />
      ) : !Boolean(projects?.length) ? (
        <h2 className="gallery-page__message">No Projects Yet</h2>
      ) : (
        <div className="gallery-page__projects">
          {projects?.map((project) => (
            <Link
              key={project.slug}
              to={`/project/${project.slug}`}
              className="gallery-page__project"
            >
              <img
                className="gallery-page__project-image"
                alt="project"
                src={project.content.main_image}
              />
              <span className="gallery-page__project-visit">
                <ArrowRight size={46} color="#fff" />
              </span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
