import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import shine from "../../assets/svgs/shines.svg";
import cart from "../../assets/svgs/cart.svg";
import ProductItem from "../product-item/ProductItem";
import { ProductType } from "./../../models/Product";
import Button from "../button/Button";
import Loading from "../loading/Loading";
import { getStoryblokData } from "../../utils/getStoryblokData";
import { useCategories } from "../../utils/useCategories";
import Modal from "../modal/Modal";
import { LayoutGrid } from "lucide-react";
import ShopBanner from "../../assets/splashes/ShopBanner";

const Shop = () => {
  const [products, setProducts] =
    useState<{ slug: string; content: ProductType }[]>();
  const [openMenuShop, setOpenMenuShop] = useState(false);
  const [loading, setLoading] = useState(true);

  const categories = useCategories();
  useEffect(() => {
    const getProducts = async () => {
      const { data: productsData } = await getStoryblokData<{
        stories: { slug: string; content: ProductType }[];
      }>("cdn/stories/", "products");
      setProducts(productsData?.stories);
      setLoading(false);
    };
    getProducts();
  }, []);
  return (
    <div className="shop-page">
      <header className="shop-page__land">
        <div className="shop-page__land-titles">
          <h1 className="shop-page__land-title-1">Be</h1>
          <img className="shop-page__shine" src={String(shine)} alt="shine" />
          <h1 className="shop-page__land-title-2">The Great</h1>
          <h1 className="shop-page__land-title-3">Proffetional</h1>
          <h1 className="shop-page__land-title-4">Designer</h1>
          <Button
            text="Shop now"
            orange
            type="button"
            onClick={() => window.scrollTo(0, 1000)}
          />
        </div>

        <ShopBanner classNames="shop-page__land-banner" />
      </header>

      <nav className="shop-page__navbar">
        <Link to="/cart">
          <img
            className="shop-page__cart-icon"
            src={String(cart)}
            alt="cart icon"
          />
        </Link>
        <Button
          text={<LayoutGrid size={46} color="#e22758" />}
          transparent
          type="button"
          onClick={() => setOpenMenuShop(true)}
        />
      </nav>

      <section className="shop-page__products-container" id="shop">
        {loading ? (
          <Loading />
        ) : (
          products?.map((product) => (
            <div className="shop-page__product-wrapper" key={product.slug}>
              <ProductItem product={product.content} slug={product.slug} />
            </div>
          ))
        )}
      </section>
      <Modal isOpen={openMenuShop} onClose={() => setOpenMenuShop(false)}>
        <div className="shop-page__categories">
          <h1 className="shop-page__categories-title">Categories</h1>
          <div className="shop-page__categories-items">
            {categories?.map((category) => (
              <Link
                to={`/cat/${category.slug}`}
                className="shop-page__category"
                key={category.slug}
              >
                <img
                  className="shop-page__category-icon"
                  src={category.content.icon}
                  alt="category icon"
                />
                <h2 className="shop-page__category-title">
                  {category.content.title}
                </h2>
              </Link>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Shop;
