import React from "react";
import { useNavigate } from "react-router-dom";

import DiscoverEye from "../../assets/splashes/DiscoverEye";
import Button from "../button/Button";
import Star from "../../assets/splashes/Star";

const DiscoverSection = () => {
  const navigate = useNavigate();

  return (
    <section className="home-page__discover-section">
      <div className="home-page__discover-section-content">
        <h1 className="home-page__discover-section-title">
          <span data-aos="fade-up">Discover</span>
          <span data-aos="fade-up" data-aos-delay="200">
            <br />
            the stories <br />
            behind each
          </span>
          <span data-aos="fade-up" data-aos-delay="400">
            <br />
            unique design
          </span>
        </h1>
        <Button
          black
          text="check it out"
          type="button"
          onClick={() => navigate("/shop")}
          vintage
        />
        <Star classNames="home-page__discover-star" color="#E22758" />
      </div>
      <DiscoverEye classNames="home-page__discover-eye" />
    </section>
  );
};

export default DiscoverSection;
