import React from "react";
import { Carousel } from "antd";

import Star from "../../assets/splashes/Star";
import FeedBackMessage from "./FeedBackMessage";

const FeedbackSliderSection = () => {
  return (
    <section className="home-page__feedback-slider">
      <Star classNames="home-page__feedback-star" color="#fc7e29" />
      <Star classNames="home-page__feedback-star" color="#fc7e29" />
      <Carousel dots autoplay>
        <div className="home-page__feedback-slider">
          <FeedBackMessage
            avatar="female-avatar1"
            username="متجر زوز "
            content="  تصاميمها ماشاءالله لا قوة الا بالله ابداع و ذوق لا تتخطونها
              لمشاريعكم اة منتجاتكم"
          />
        </div>
        <div className="home-page__feedback-slider">
          <FeedBackMessage
            avatar="male-avatar2"
            username="Soon"
            content="    الله يسعدك .. ماشاء الله تبارك الرحمن قمه في الجمال والابداع
        جداً اغرمت بالشعار صراحة 🤩😭❤️ تسلم أناملك ياروحي.🙏🏻❤️"
          />
        </div>
        <div className="home-page__feedback-slider">
          <FeedBackMessage
            avatar="female-avatar2"
            username="المهاجر"
            content="  جزيل الشكر والتقدير والعرفان على تصميمك الرائع ماشاء الله عليك
        كفو بارك الله فيك"
          />
        </div>
      </Carousel>
    </section>
  );
};

export default FeedbackSliderSection;
