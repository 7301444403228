import React from "react";
import { getAvatar } from "../../utils/getAvatar";
import { Heart, Star } from "lucide-react";
interface Props {
  username: string;
  avatar: string;
  content: string;
}

const FeedBackMessage = ({ avatar, content, username }: Props) => {
  return (
    <div className="home-page__feedback">
      <img
        className="home-page__feedback-body-image"
        src={getAvatar(avatar)}
        alt="Avatar"
      />
      <div className="home-page__feedback-info">
        <div className="home-page__feedback-activity-bar">
          <div className="home-page__feedback-stars">
            <Star color="#fc7e29" fill="#fc7e29" />
            <Star color="#fc7e29" fill="#fc7e29" />
            <Star color="#fc7e29" fill="#fc7e29" />
            <Star color="#fc7e29" fill="#fc7e29" />
            <Star color="#fc7e29" fill="#fc7e29" />
          </div>
          <Heart className="home-page__feedback-stars" fill="#e22758" />
        </div>
        <div className="home-page__feedback-username">{username}</div>
        <div className="home-page__feedback-content">{content}</div>
      </div>
    </div>
  );
};

export default FeedBackMessage;
