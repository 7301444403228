import React from "react";

const Star = ({ classNames, color }) => {
  return (
    <div className={classNames}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 342 467" fill="none">
        <path
          fill={color}
          d="m0 234 36-8c43-13 72-41 94-78 18-33 29-68 35-105l5-40 1-2 3 27c6 47 18 91 45 131 28 43 67 68 119 73l3 1-27 5c-44 11-76 37-99 75-20 32-31 68-38 105l-7 49-3-28c-6-47-18-91-44-131-26-41-64-67-113-73l-10-1Z"
        />
      </svg>
    </div>
  );
};

export default Star;
