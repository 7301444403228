import React from "react";

import SandWatch from "../../assets/splashes/SandWatch";
import GreatThingsTab from "../../assets/splashes/GreatThingsTab";
import HeartIcon from "../../assets/splashes/HeartIcon";

const GreatThingsSection = () => {
  return (
    <section className="home-page__great-things">
      <SandWatch classnames="home-page__great-things-sand-watch" />
      <GreatThingsTab classNames="home-page__great-things-tab" />

      <HeartIcon classNames="home-page__great-things-heart" />
    </section>
  );
};

export default GreatThingsSection;
