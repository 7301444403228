import React, { createContext, useEffect, useState } from "react";

import Message from "../components/message/Message";

const initialMessageContext = {
  onError: (_message: any) => {},
  onSuccess: (_message: any) => {},
};

export const MessageContext = createContext(initialMessageContext);

const MessageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<"success" | "error">(
    "success"
  );

  const onSuccess = (message: string) => {
    setMessage(message);
    setMessageType("success");
  };

  const onError = (message: string) => {
    setMessage(message);
    setMessageType("error");
  };

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  }, [message]);

  return (
    <MessageContext.Provider value={{ onError, onSuccess }}>
      <Message content={message} show={Boolean(message)} type={messageType} />
      {children}
    </MessageContext.Provider>
  );
};

export default MessageContextProvider;
