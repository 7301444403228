import React from "react";

const LandBows = ({classnames}) => {
  return (
    <div className={classnames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 589 424"
        fill="none"
      >
        <path
          fill="#FF5052"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="4.3"
          d="M457.3 393.7a185.3 185.3 0 1 0-262-262 185.3 185.3 0 0 0 262 262Z"
        />
        <path
          fill="#E22758"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="4.3"
          d="M432.7 398.7a150.6 150.6 0 1 0-213-213 150.6 150.6 0 0 0 213 213Z"
        />
        <path
          fill="#FFDCC5"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="4.3"
          d="M326.3 436.8a120.7 120.7 0 1 0 0-241.4 120.7 120.7 0 0 0 0 241.4Z"
        />
        <path
          fill="#E22758"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="4.3"
          d="M170.8 535.8a168 168 0 1 0 0-336 168 168 0 0 0 0 336Z"
        />
        <path
          fill="#FF5052"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="4.3"
          d="M189.7 523.3a136.5 136.5 0 1 0 0-273.1 136.5 136.5 0 0 0 0 273Z"
        />
        <path
          fill="#FC7E29"
          stroke="#191E32"
          stroke-miterlimit="10"
          stroke-width="4.3"
          d="M205 511.5a109.5 109.5 0 1 0 0-219 109.5 109.5 0 0 0 0 219Z"
        />
        <path
          fill="#FFDCC5"
          stroke="#000"
          stroke-miterlimit="10"
          stroke-width="4.3"
          d="M220.4 499a81.6 81.6 0 1 0 0-163.2 81.6 81.6 0 0 0 0 163.2Z"
        />
      </svg>
    </div>
  );
};

export default LandBows;
