import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

import { ProductType } from "../../models/Product";
import { getFinalPrice } from "../../utils/getFinalPrice";
import Modal from "../modal/Modal";
import cart from "../../assets/svgs/cart.svg";
import Button from "../button/Button";
import classNames from "classnames";
import Loading from "../loading/Loading";
import { getStoryblokData } from "../../utils/getStoryblokData";
import { renderRichText } from "@storyblok/react";

export default function Product() {
  const [product, setProduct] = useState<ProductType | undefined>();
  const [showAddMsg, setShowAddMsg] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user, setUser, isAuth } = useContext(UserContext);

  const param = useParams();
  const navigate = useNavigate();

  const productDescription =
    product?.description && renderRichText(product?.description!);

  useEffect(() => {
    const getProduct = async () => {
      const { data: blog } = await getStoryblokData<{
        story: { content: ProductType };
      }>(`cdn/stories/products/${param?.slug}`);
      setProduct(blog?.story.content);
      setLoading(false);
    };
    getProduct();
  }, []);

  const addToCart = () => {
    if (isAuth && product) {
      const filterdCart = user.cart.filter((item) => item.id !== product.id);
      const IsIncart = user.cart.find((item) => item.id === product.id);
      if (IsIncart) {
        setUser({
          ...user,
          cart: [...filterdCart, { ...product, count: product.count + 1 }],
        });
        setShowAddMsg(true);
      } else {
        setUser({ ...user, cart: [...user.cart, product] });
        setShowAddMsg(true);
      }
    } else {
      navigate("/sign");
    }
  };

  const downloadFile = () => {
    if (isAuth) {
      if (product) {
        const link = document.createElement("a");
        link.setAttribute("href", product.file);
        link.download = product.file;
        link.click();
      }
    } else {
      navigate("/sign");
    }
  };

  if (loading) return <Loading />;
  return (
    <div className="product-page">
      <div className="product-page__image-wrapper">
        <img
          className="product-page__image"
          src={product?.second_image}
          alt="product"
        />
      </div>

      <div className="product-page__info">
        <h1 className="product-page__title">{product?.title}</h1>
        <h2 className="product-page__price">
          <span
            className={classNames("", {
              "product__price--discount": product?.discount !== 0,
            })}
          >
            {product?.price} SAR
          </span>
          {product &&
            product?.discount !== 0 &&
            getFinalPrice(product.price, product?.discount) + " SAR"}

          {product?.discount && (
            <span className="product-page__discount">
              {product?.discount}% OFF
            </span>
          )}
        </h2>
        {product?.price && product?.price > 0 ? (
          <button className="product-page__add-to-cart-btn" onClick={addToCart}>
            ADD TO CART
          </button>
        ) : (
          <button
            className="product-page__add-to-cart-btn"
            onClick={downloadFile}
          >
            I want this
          </button>
        )}
        {productDescription && (
          <span
            className="product-page__product-desc"
            dangerouslySetInnerHTML={{ __html: productDescription! }}
          />
        )}
      </div>

      <Modal isOpen={showAddMsg} onClose={() => setShowAddMsg(false)}>
        <h2 className="product-page__msg-title">
          YOUR ITEM ADDED SUCCESSFULLY!
        </h2>
        <img
          src={String(cart)}
          alt="cart"
          className="product-page__cart-image"
        />
        <span className="product-page__cart-counter">{user?.cart?.length}</span>
        <div className="product-page__modal-actions">
          <Button
            text="Keep Shopping"
            type="button"
            pink
            onClick={() => navigate("/shop")}
          />
          <Button
            text="Check the cart"
            orange
            type="button"
            onClick={() => navigate("/cart")}
          />
        </div>
      </Modal>
    </div>
  );
}
