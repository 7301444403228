import React, { useState } from "react";
import NavItem from "./NavItem";
import { Link } from "react-router-dom";
import { Menu } from "lucide-react";
import MenuAside from "../menu-aside/MenuAside";

import Button from "../button/Button";
const logo = require("../../assets/images/logo.png");

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <nav className="navbar">
      <Link to={"/"}>
        <img className="navbar__logo-img" alt="logo" src={String(logo)} />
      </Link>

      <div className="navbar__navs">
        <NavItem label="blogs" link="/blogs" />
        <NavItem label="shop" link="/shop" />
        <NavItem label="gallery" link="/gallery" />
        <NavItem label="breath" link="/breath" />
      </div>
      <div className="navbar__menu">
        <Button
          onClick={() => setOpenMenu(true)}
          text={<Menu size={46} color="#e22758" />}
          transparent
          type="button"
        />
      </div>
      <MenuAside openMenu={openMenu} onCloseMenu={() => setOpenMenu(false)} />
    </nav>
  );
};

export default Navbar;
