import React from "react";

import HeartIcon from "../../assets/splashes/HeartIcon";
import Button from "../button/Button";
import ShopBanner from "../../assets/splashes/ShopBanner";

const ShopSection = () => {
  return (
    <section className="home-page__shop">
      <div className="home-page__shop-content">
        <h1 className="home-page__shop-title">
          <span>
            <span style={{color: '#fc7e29'}}>STEP IN</span> <br />
          </span>{" "}
          AND <HeartIcon classNames="home-page__shop-heart" bgColor="#e22758" />
          <br />
          EXPLORE
        </h1>
        <Button black text="check it out" type="button" vintage />
      </div>
      <div className="home-page__shop-image">
        <ShopBanner classNames="home-page__shop-banner" />
      </div>
    </section>
  );
};

export default ShopSection;
