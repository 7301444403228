import React, { FormEvent, useContext, useState } from "react";
import Input from "../form/input/Input";
import axios from "axios";
import { apiurl } from "../../App";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import Button from "../button/Button";
import Radio from "../form/radio/Radio";

interface Props {
  onError: (_message: any) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterCard = ({ setLoading, onError, setIsLogin }: Props) => {
  const [registerForm, setRegisterForm] = useState({
    username: "",
    email: "",
    password: "",
    gender: "",
    avatar: "",
  });

  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const onRegister = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(apiurl + "/register", {
        username: registerForm.username,
        email: registerForm.email,
        password: registerForm.password,
        gender: registerForm.gender,
        avatar: registerForm.avatar,
      })
      .then((res) => {
        const { data } = res;
        if (data.error) {
          onError(data.error);
          setLoading(false);
        } else {
          const  {created_at, updated_at, ...rest} = data
          setUser(rest);
          localStorage.setItem("token", data.id);
          localStorage.setItem("email", data.email);
          navigate("/");
          console.log(data);
        }
      })
      .catch((err) => onError(String(err)));
  };

  return (
    <div className="register-card">
      <h1 className="register-card__head">Register</h1>
      <p className="register-card__message">
        Already have account?
        <span onClick={() => setIsLogin((prev) => !prev)}>LOGIN</span>
      </p>
      <form onSubmit={onRegister} className="register-card__form">
        <Input
          name="username"
          value={registerForm.username}
          onChange={(e) =>
            setRegisterForm({ ...registerForm, username: e.target.value })
          }
          isRequired
          placeholder="Username"
        />
        <Input
          name="email"
          value={registerForm.email}
          onChange={(e) =>
            setRegisterForm({ ...registerForm, email: e.target.value })
          }
          isRequired
          type="email"
          placeholder="Email"
        />
        <Input
          type="password"
          name="password"
          placeholder="Password"
          value={registerForm.password}
          onChange={(e) =>
            setRegisterForm({ ...registerForm, password: e.target.value })
          }
          isRequired
        />
        <Radio
          name="gender"
          options={[
            { label: "Male", value: "male" },
            { label: "Female", value: "female" },
          ]}
          orientation="horizontal"
          selected={registerForm.gender}
          onSelect={(e) =>
            setRegisterForm({ ...registerForm, gender: e.target.value })
          }
        />
        <Button type="submit" black text="Register" />
      </form>
    </div>
  );
};

export default RegisterCard;
