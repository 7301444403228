import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

import { getAvatar } from "../../utils/getAvatar";
import MenuAsideItem from "./MenuAsideItem";
import { X } from "lucide-react";
import Button from "../button/Button";

const items = [
  {
    label: "join our community",
    to: "https://t.me/SuperDesiners",
    type: "external",
  },
  {
    label: "contact",
    to: "/contact",
    type: "internal",
  },
  {
    label: "about",
    to: "/about",
    type: "internal",
  },
  {
    label: "shop",
    to: "/shop",
    type: "internal",
  },
  {
    label: "blogs",
    to: "/blogs",
    type: "internal",
  },
  {
    label: "gallery",
    to: "/gallery",
    type: "internal",
  },
  {
    label: "breath",
    to: "/breath",
    type: "internal",
  },
];
interface Props {
  openMenu: boolean;
  onCloseMenu: () => void;
}

const MenuBar = ({ openMenu, onCloseMenu }: Props) => {
  const { user, setUser, isAuth } = useContext(UserContext);

  const logout = () => {
    localStorage.clear();
    setUser(null);
  };

  if (!openMenu) return null;

  return (
    <div className="menu-aside__container" onClick={onCloseMenu}>
      <aside className="menu-aside" onClick={(e) => e.stopPropagation()}>
        <header className="menu-aside__header">
          <Link
            to="/profile"
            className="menu-aside__user-profile"
            onClick={onCloseMenu}
          >
            <img
              className="menu-aside__user-profile-avatar"
              src={getAvatar(user.avatar) as string}
              alt="avatar"
            />
            <span className="menu-aside__user-profile-username">
              {user.username || "Guest"}
            </span>
          </Link>
          <Button
            onClick={onCloseMenu}
            text={<X onClick={onCloseMenu} size={46} color="#e22758" />}
            transparent
            type="button"
          />
        </header>

        <div className="menu-aside__items">
          {items.map((item) => (
            <MenuAsideItem
              label={item.label}
              key={item.label}
              to={item.to}
              type={item.type}
              onClick={onCloseMenu}
            />
          ))}

          <MenuAsideItem
            label={!isAuth ? "sign-in" : "log-out"}
            onClick={() => {
              if (isAuth) {
                logout();
                onCloseMenu();
              } else {
                onCloseMenu();
              }
            }}
            type="internal"
            to="/sign"
          />
        </div>
      </aside>
    </div>
  );
};

export default MenuBar;
