import React, { FormEvent, useContext, useEffect, useState } from "react";
import Button from "../button/Button";
import { apiurl } from "../../App";
import { MessageContext } from "../../context/MessageContext";
import { getAvatar } from "../../utils/getAvatar";
import { useNavigate } from "react-router-dom";
import UpdateProfileModal, { EditFormType } from "./UpdateProfileModal";
import axios from "axios";
import Loading from "../loading/Loading";
import { PostType } from "../../models/Post";
import BreathPost from "../breath/BreathPost";
import { UserContext } from "../../context/UserContext";

const Profile = () => {
  const { user, setUser, isAuth } = useContext(UserContext);
  const { onError, onSuccess } = useContext(MessageContext);

  const [posts, setPosts] = useState<PostType[]>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editForm, setEditForm] = useState<EditFormType>({
    username: "",
    email: "",
    avatar: "",
  });

  const navigate = useNavigate();

  const onDeletePost = (id: string) => {
    axios
      .post(apiurl + "/delete-post/" + id)
      .then((res) => {
        if (res.data.error) {
          onError("Failed to delete the post");
        } else {
          const updatedPosts = posts?.filter((post) => post.id !== id);
          setPosts(updatedPosts);
          onSuccess("Deleted Successfully");
        }
      })
      .catch(() => onError("Failed to delete!"));
  };

  const updateProfile = (e: FormEvent) => {
    e.preventDefault();

    if (isAuth) {
      axios
        .post(apiurl + "/update-profile/" + user.id, editForm)
        .then((res) => {
          if (res.data.error) {
            onError("Failed to Update the Profile! Try later.");
          } else {
            setUser({ ...user, ...res.data });
            onSuccess("Updateed Successfully!");
          }
        })
        .catch(() => onError("Some issue in updating!!"));
    }
  };

  useEffect(() => {
    setEditForm({
      username: user.username,
      email: user.email,
      avatar: user.avatar,
    });

    fetch(apiurl + "/my-posts/" + user.id)
      .then((res) => res.json())
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [showModal, user]);

  return (
    <div className="profile-page">
      <h1 className="profile-page__header">My Profile</h1>
      <div className="profile-page__avatar-holder">
        <img
          className="profile-page__avatar"
          src={String(getAvatar(user.avatar))}
          alt="avatar"
        />
      </div>
      <h2 className="profile-page__greet">Hi {user.username || "Geust"}!</h2>
      {user.username ? (
        <div className="profile-page__card">
          <span className="profile-page__info">
            <b>Username: </b>
            {user.username}
          </span>
          <span className="profile-page__info">
            <b>Email: </b>
            {user.email}
          </span>
          <Button
            type="button"
            orange
            text="Edit"
            onClick={() => setShowModal(true)}
          />
        </div>
      ) : (
        <Button
          pink
          type="button"
          onClick={() => navigate("/sign")}
          text="Login"
        />
      )}
      {user.email === "x.mxdbs@gmail.com" && (
        <Button
          text="Go to Admin Panel"
          type="button"
          onClick={() => navigate("/admin")}
          pink
        />
      )}
      <h1 className="profile-page__title-posts">Your posts</h1>
      {loading ? (
        <Loading />
      ) : posts?.length === 0 ? (
        <p>No Posts yet</p>
      ) : (
        <div className="breath-page__posts">
          {posts
            ?.map((post) => (
              <BreathPost
                post={post}
                key={post.id}
                onDeletePost={onDeletePost}
              />
            ))
            .reverse()}
        </div>
      )}
      <UpdateProfileModal
        editForm={editForm}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        setEditForm={setEditForm}
        updateProfile={updateProfile}
      />
    </div>
  );
};

export default Profile;
