import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Comments from "../comments/Comments";
import { BlogType } from "../../models/Blog";
import { CommentType } from "../../models/Comment";
import Loading from "../loading/Loading";
import { getStoryblokData } from "../../utils/getStoryblokData";
import { renderRichText } from "@storyblok/react";

const Blog = () => {
  const [BlogComments, setBlogComments] = useState<CommentType[]>([]);
  const [blog, setBlog] = useState<BlogType>();
  const [loading, setLoading] = useState(true);
  const { isAuth } = useContext(UserContext);

  const param = useParams();
  const content = blog && renderRichText(blog.content);

  function isArabic(str: string): boolean | undefined {
    // Arabic Unicode Range: U+0600 - U+06FF
    // This regular expression checks if any character in the string belongs to the Arabic Unicode range
    const arabicRegex = /[\u0600-\u06FF]/;

    let arabicCount = 0;
    let totalCount = 0;

    for (const char of str) {
      if (arabicRegex.test(char)) {
        arabicCount++;
      }
      totalCount++;
    }

    // Check if the string starts with Arabic or if the majority of characters are Arabic
    return arabicCount / totalCount >= 0.5 || arabicRegex.test(str[0]);
  }

  useEffect(() => {
    const getBlog = async () => {
      const { data: blog } = await getStoryblokData<{
        story: { content: BlogType };
      }>(`cdn/stories/blog/${param?.slug}`);
      setBlog(blog?.story.content);
      setLoading(false);
    };
    getBlog();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div className="blog-page">
      <img className="blog-page__image" src={blog?.image} alt={blog?.title} />
      <h1 className="blog-page__title">{blog?.title}</h1>
      {content && (
        <span
          dir={isArabic(blog.title) ? "rtl" : "ltr"}
          className="blog-page__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}

      {blog && (
        <Comments
          replyEndPoint="/replies"
          comments={BlogComments}
          isAuth={isAuth}
          contentId={param.slug!}
          updateContent={setBlogComments}
          endPoint="/comments"
        />
      )}
    </div>
  );
};

export default Blog;
